@font-face {
  font-family: 'hello baby';
  src: url(./hello_baby.ttf);
}

@font-face {
  font-family: 'Roboto';
  src: url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(./Roboto-Bold.ttf);
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(./Roboto-Light.ttf);
}

@font-face {
  font-family: 'Athelas';
  src: url(./Athelas.ttf);
}
 
@font-face {
  font-family: 'LondonBetween';
  src: url(./LondonBetween.ttf);
}
@font-face {
  font-family: 'LondonMM';
  src: url(./LondonMM.ttf);
}
@font-face {
  font-family: 'LondonTwo';
  src: url(./LondonTwo.ttf);
}